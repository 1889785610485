<template>
  <div class="d-flex flex-column justify-content-between" style="min-height:100vh;">
    <div>
        <Header :showAction="false"></Header>
    <div class="new-visit container bg-light p-4 mt-4 rounded-3">
      <div class="row" v-if="message">
        <div class="col-md-12">
          <div v-bind:class="`alert alert-${message.type}`">
            <strong v-text="message.prefix"></strong>
            <span v-html="message.message"></span>
          </div>
        </div>
      </div>

      <div class="row" v-if="loading">
        <div class="col-md-12">
          <span>Caricamento...</span>
        </div>
      </div>

      <div class="row" v-if="!message?.block && !loading">
        <div class="col-md-12 text-center">
          <h1
            v-text='ticket.subject'
          ></h1>
          <small
            class="d-block mb-3"
            v-text="'Ticket number: ' + ticket.id"
          ></small>
        </div>
      </div>
      <div class="row" v-if="!message?.block && !loading">
        <div class="col-md-12">
          <form action="" class="form">


            <div class="row mt-3">
              <div class="col-md-12">
                <label for="visit-description" class="form-label mb-0"
                  >Purchasing order number / Numéro d'ordre d'achat *</label
                >
                <input
                  class="form-control"
                  id="visit-description"
                  rows="3"
                  v-model="ticket.customer_order_number"
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  @click="submit"
                  v-text="'Save info / Sauvegarder l\'info'"
                ></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { HubspotController } from "../controller/hubspot";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

export default {
  data() {
    return {
      loading: true,
      message: null,
      hsController: new HubspotController(),
      ticket: {
        customer_order_number: ""
      }
    };
  },

  async mounted() {
    this.init();
  },

  watch: {
    $route: function (to, from) {
      this.init();
    },
  },

  methods: {
    async init() {
      document.title = "Fantini | Caricamento...";
      this.loading = true;
      const ticketId = this.$route.query.ticketId;

      if (ticketId) {
        await this.hsController
          .getTicket(ticketId)
          .then((ticket) => {
            console.log({ticket})
            this.ticket = ticket.properties;
            this.ticket.id = ticket.id;
          })
          .catch((e) => {
            console.log(e);
            this.message = {
              type: "danger",
              prefix: "Attenzione:",
              blockFlag: true,
              message:
                "Ticket non trovato, potrebbe essere stato cancellato oppure l'ID non essere corretto",
            };
          });
      }else{
        this.message = {
              type: "danger",
              prefix: "Attenzione:",
              blockFlag: true,
              message:
                "Ticket non trovato, potrebbe essere stato cancellato oppure l'ID non essere corretto",
            };
      }

      document.title = "Fantini | " + (this.ticket.subject ?? "More info");
      this.loading = false;
    },

    async submit() {
      this.message = null;

      const showWarning = (message) => {
        this.message = {
          type: "warning",
          prefix: "Attenzione: ",
          message: message,
        };
        window.scrollTo(0, 0);
      };



      if (this.ticket.id) {
        if (!this.ticket.customer_order_number) {
          showWarning("Il numero ordine è obbligatorio");
          return false;
        }
      } 


      this.loading = true;

      if (this.ticket.id) {
        try {
          await this.hsController.enrichTicketInfo(this.ticket.id,{
            customer_order_number: this.ticket.customer_order_number
          });
          this.message = {
              type: "success",
              prefix: "Complimenti:",
              blockFlag: true,
              message:
                " Purchasing order number sent correctly",
            };
        } catch (e) {
          console.error(e);
          this.loading = false;
          this.message = {
            type: "danger",
            prefix: "Attenzione:",
            message:
              "Errore durante la creazione del ticket, ricontrollare i campi",
          };
          return false;
        }
      }else{
        this.message = {
              type: "danger",
              prefix: "Attenzione:",
              blockFlag: true,
              message:
                "Ticket non trovato, potrebbe essere stato cancellato oppure l'ID non essere corretto",
            };
        this.loading = false;

        return false;
      }



      this.loading = false;
    },

  },

  components: {
    Header,
    Footer,
  },
};
</script>
